<template>
    <div class="layout-topbar">
        <div class="topbar-left">
<!--            <a tabindex="0" class="menu-button" @click.prevent="sidebarMenuState = !sidebarMenuState">
                <i class="pi pi-angle-left"></i>
            </a>-->

            <router-link to="/" class="horizontal-logo">
                <img id="logo-horizontal" src="assets/layout/images/emersion-logo-white.png" alt="diamond-layout"/>
            </router-link>

            <span class="topbar-separator"></span>

            <div class="layout-breadcrumb viewname" style="text-transform: uppercase">
                <template v-if="$route.meta.breadcrumb">
                    <span>{{ $route.meta.breadcrumb[0].label }}</span>
                </template>
            </div>

            <img id="logo-mobile" class="mobile-logo" src="assets/layout/images/emersion-logo-blue.png"
                 alt="diamond-layout"/>
        </div>

        <AppMenu
            :model="menu"
            :layoutMode="layoutMode"
            :mobileMenuActive="staticMenuMobileActive"
            @menu-click="onMenuClick"
            @menuitem-click="onMenuItemClick"
            @root-menuitem-click="onRootMenuItemClick"
        />

        <div class="layout-mask modal-in"></div>

        <div class="topbar-right">
            <ul class="topbar-menu">
                <li class="profile-item" :class="{ 'active-menuitem fadeInDown': topbarLanguageMenuActive }">
                    <a href="javascript:void(0);" @click="onTopbarLanguageMenuButtonClick">
                        <span class="profile-name">Language:&nbsp;</span>
                        <i class="flag flag-size" :class="flagCode[selectedLanguage]">&nbsp;</i>
                    </a>
                    <ul class="profile-menu fade-in-up" style="min-width: 100px !important">
                        <li>
                            <a href="javascript:void(0);" :class="selectedLanguage === 'en-gb' ? 'disabled' : ''"
                               @click="onChangeLanguageClick('en-gb')">
                                <i class="flag flag-gb flag-size">&nbsp;</i>
                                <span class="profile-name">English&nbsp;(UK)</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" :class="selectedLanguage === 'en-us' ? 'disabled' : ''"
                               @click="onChangeLanguageClick('en-us')">
                                <i class="flag flag-us flag-size">&nbsp;</i>
                                <span class="profile-name">English&nbsp;(US)</span>
                            </a>
                        </li>
                        <li>
                            <a href="javascript:void(0);" :class="selectedLanguage === 'en-au' ? 'disabled' : ''"
                               @click="onChangeLanguageClick('en-au')">
                                <i class="flag flag-au flag-size">&nbsp;</i>
                                <span class="profile-name">English&nbsp;(AU)</span>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="profile-item" :class="{ 'active-menuitem fadeInDown': topbarUserMenuActive }">
                    <a href="#" @click="onTopbarUserMenuButtonClick">
                        <img src="assets/layout/images/emersion-logo-blue.png" alt="diamond-layout"
                             class="profile-image"/>
                        <span class="profile-name">{{ loggedName }}</span>
                    </a>
                    <ul class="profile-menu fade-in-up">
                        <li>
                            <div class="px-2">
                                <div class="block mb-3">
                                    <label class="block mb-2 font-bold text-xl">{{ userAccountName }}</label>
                                    <label class="block mb-2">{{ userNameRealm }}</label>
                                    <label class="block mb-3">{{ userServiceProviderName }}</label>
                                </div>

                                <div class="block mb-2 border-bottom-2 surface-border surface-overlay">
                                    <div class="grid">
                                        <div class="col-5" v-if="userAccountID">
                                            <label class="font-bold">{{ $t('lbl_account_id', 'Account ID') }}</label>
                                            <label class="block">{{ userAccountID }}</label>
                                        </div>
                                        <div class="col-7" v-if="userParentAccountID">
                                            <label class="font-bold">{{ $t('lbl_parent_account_id', 'Parent Account Id')
                                                }}</label>
                                            <label class="block">{{ userParentAccountID }}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </li>
                        <li class="px-2">
                            <div class="mb-1 border-bottom-1 surface-border surface-overlay">
                                <a href="#/user-profile" class="active-route router-link-exact-active p-ripple">
                                    <i class="mr-2 pi pi-user"></i>
                                    <span>{{ $t('lbl_apptopbar_account_details', 'Account Details') }}</span>
                                </a>
                            </div>
                        </li>
                        <li class="px-2">
                            <div class="mb-1 border-bottom-1 surface-border surface-overlay">
                                <a href="#/user-profile/auth">
                                    <i class="mr-2 pi pi-key"></i>
                                    <span>{{ $t('lbl_change_password', 'Change Password') }}</span>
                                </a>
                            </div>
                        </li>
                        <li class="px-2">
                            <div class="mb-1">
                                <a href="#" @click.prevent="logout">
                                    <i class="mr-2 pi pi-sign-out"></i>
                                    <span>{{ $t('lbl_sign_out', 'Sign Out') }}</span>
                                </a>
                            </div>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
    <Dialog v-model:visible="promptIfLogout" :style="{ width: '450px' }" :header="$t('lbl_confirm', 'Confirm')"
            :modal="true">
        <div class="confirmation-content">
            <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"/>
            <span>{{ $t('lbl_confirm_logout', 'Are you sure you want to logout ?') }}</span>
        </div>
        <template #footer>
            <Button :label="$t('btn_no', 'No')" :disabled="disableYesButtonLogout" icon="pi pi-times"
                    class="p-button-text" @click="promptIfLogout = false"/>
            <Button :label="$t('btn_yes', 'Yes')" :disabled="disableYesButtonLogout" icon="pi pi-check"
                    class="p-button-text" @click="continueLogout"/>
        </template>
    </Dialog>
</template>

<script>
import { onMounted, computed, ref } from 'vue';
import AppMenu from './AppMenu';
import UserAuthenticationService from './service/UserAuthenticationService';
import EventBus from './event-bus';
import DataTableService from './service/DataTableService';
import LanguageService from './service/LanguageService';
import NimbusUtilities, { getColor } from '@/service/NimbusUtilities';
import { getCurrencyByServiceProvider, getCurrencySetting, removeCurrency } from '@/service/CurrencyService';
import { logoutCurrent } from '@/service/UserService';
import { useAutoTimer } from "@/stores/auto-timer";
import { Constants } from "@/service/Constants";
import { getUserPortalSettings } from "@/service/EndUserPortalService";
import { useUserStore } from "@/stores/user-store";
import { useEUPStore } from "@/stores/eup-store";
import { forceRemoveDataInAutoLogout } from "@/service/NimbusLSService";
import { useAuthStore } from '@/stores/authStore';

export default {
    name: 'AppTopbar',
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click', 'menu-button-click', 'search-click', 'topbar-notification', 'topbar-user-menu', 'topbar-language-menu', 'right-menubutton-click', 'change-language-click'],
    props: {
        topbarNotificationMenuActive: Boolean,
        topbarUserMenuActive: Boolean,
        topbarLanguageMenuActive: Boolean,
        layoutMode: String,
        menu: Array,
        menuActive: Boolean,
        staticMenuMobileActive: Boolean,
    },
    data() {
        return {
            loggedName: '',
            userAccountName: '',
            userNameRealm: '',
            userServiceProviderName: '',
            userAccountID: '',
            userParentAccountID: '',
            userService: null,
            items: [],
            promptIfLogout: false,
            disableYesButtonLogout: false,
            dtService: null,
            languageService: null,
            selectedLanguage: 'en-gb',
            flagCode: {
                'en-us': 'flag-us',
                'en-gb': 'flag-gb',
                'en-au': 'flag-au',
            },
            portalId: null,
            branding: {
                'main_color': Constants.DEFAULT_MAIN_COLOR,
                'active_color': Constants.DEFAULT_ACTIVE_COLOR,
                'hover_color': Constants.DEFAULT_HOVER_COLOR
            },
            authStore: useAuthStore(),
        };
    },
    components: {
        AppMenu,
    },
    setup() {
        const eupStore = useEUPStore();
        const sidebarMenuState = ref(true);

        // converting input computed to quick return the values on the default or eup setup
        const mainColor = computed(() => {
            return (eupStore.eupSettings
                && getColor(eupStore.eupSettings.main_color))
                ? getColor(eupStore.eupSettings.main_color)
                : Constants.DEFAULT_MAIN_COLOR
        });
        const activeColor = computed(() => {
            return (eupStore.eupSettings
                && getColor(eupStore.eupSettings.active_color))
                ? getColor(eupStore.eupSettings.active_color)
                : Constants.DEFAULT_MAIN_COLOR
        });
        const hoverColor = computed(() => {
            return (eupStore.eupSettings
                && getColor(eupStore.eupSettings.hover_color))
                ? getColor(eupStore.eupSettings.hover_color)
                : Constants.DEFAULT_MAIN_COLOR
        });

        onMounted(() => {
            // by default set the eup settings in state
            useEUPStore().setEUPSettings();
        })

        return {
            mainColor,
            activeColor,
            hoverColor,
            sidebarMenuState,
        }
    },
    mounted() {
        this.languageService = new LanguageService();
        this.selectedLanguage = this.languageService.getSelectedLanguage();
        this.userService = new UserAuthenticationService();
        this.dtService = new DataTableService();
        this.nimbusutilService = new NimbusUtilities();
        this.getVerifiedUser();
        this.getCurrency();
    },
    async updated() {
        // commented this for now, as EUP modules are still in work progress
        // await this.loadNimbusDefaultBranding();
    },
    unmounted() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    },
    methods: {
        loadNimbusDefaultBranding() {
            // move to setup() and computed conditions
            let eupSetting = useEUPStore().eupSettings;

            this.branding.main_color = (eupSetting && getColor(eupSetting.main_color)) ? getColor(eupSetting.main_color) : Constants.DEFAULT_MAIN_COLOR;
            this.branding.active_color = (eupSetting && getColor(eupSetting.active_color)) ? getColor(eupSetting.active_color) : Constants.DEFAULT_ACTIVE_COLOR;
            this.branding.hover_color = (eupSetting && getColor(eupSetting.hover_color)) ? getColor(eupSetting.hover_color) : Constants.DEFAULT_HOVER_COLOR;
        },
        onChangeLanguageClick(lang) {
            this.selectedLanguage = lang;
            this.$emit('change-language-click', lang);
        },
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
        onMenuButtonClick(event) {
            this.$emit('menu-button-click', event);
        },
        onSearchClick(event) {
            this.$emit('search-click', event);
        },
        onTopbarNotificationMenuButtonClick(event) {
            this.$emit('topbar-notification', event);
        },
        onTopbarUserMenuButtonClick(event) {
            this.$emit('topbar-user-menu', event);
        },
        onTopbarLanguageMenuButtonClick(event) {
            this.$emit('topbar-language-menu', event);
        },
        onRightMenuClick(event) {
            this.$emit('right-menubutton-click', event);
        },
        isMobile() {
            return window.innerWidth <= 1091;
        },
        logout() {
            this.promptIfLogout = true;
        },
        async continueLogout() {
            this.disableYesButtonLogout = true;

            try {
                await logoutCurrent();

                // clear user details state
                useUserStore().clearUserData();
                // reset the values in auto timer once logout
                useAutoTimer().resetStateValues();

                // force the storage to clear the data
                forceRemoveDataInAutoLogout();
            } catch (error) {
                console.log(error);
            }

            this.dtService.clearDTColumnsAndFilter();
            this.userService.removePermissions();
            this.userService.removeItemInLocalStorage('nimbus-session');

            await this.userService.setBearer(null);
            await this.userService.removeAuthenticatedUserID();
            await this.userService.setTwoFactorAuthentication(null);
            removeCurrency();

            await this.$router.push('/login');
        },
        async setAuthenticatedUser() {
            await this.authStore.loadDetails()
            const authDetails = this.authStore.details;

            this.loggedName = typeof authDetails.name != 'undefined' ? authDetails.name : authDetails.username;
            this.userAccountName = this.nimbusutilService.nullChecker(authDetails?.fullName);
            this.userNameRealm = this.nimbusutilService.nullChecker(authDetails?.username);
            this.userAccountID = this.nimbusutilService.nullChecker(authDetails?.accountId);
            this.userServiceProviderName = this.nimbusutilService.nullChecker(authDetails?.serviceProviderName);
            this.userParentAccountID = this.nimbusutilService.nullChecker(authDetails?.parentAccountId);
        },

        async getCurrency() {
            let currencySetting = getCurrencySetting();
            if (currencySetting == null) {
                await getCurrencyByServiceProvider();
            }
        },

        getVerifiedUser() {
            if (this.userService.isAuthenticated()) {
                this.setAuthenticatedUser();
            } else {
                EventBus.on('loggedIn', () => {
                    this.setAuthenticatedUser();
                });
            }
        },
    },
};
</script>
<style>
.flag-size {
    width: 20px;
    height: 15px;
}

.layout-topbar .topbar-menu > li > ul {
    min-width: 348px;
}

.layout-sidebar-darkgray .layout-sidebar {
    background: v-bind(mainColor);
}

#app .layout-sidebar .active-menuitem {
    background: v-bind(activeColor);
}

#app .layout-sidebar .p-ripple:hover {
    background: v-bind(hoverColor);
}
</style>
