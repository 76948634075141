<template>
    <aside class="nb-layout-sidebar" @click="onMenuClick">
        <nav>
            <menu v-if="model" class="nb-layout-sidebar__main-menu" role="menu">
                <li class="nb-layout-sidebar__logo">
                    <router-link to="/">
                        <img
                            id="app-logo"
                            :src="smallLogo"
                            alt="emersion-logo"
                            :class="isEUP ? 'w-full' : ''"
                        />
                    </router-link>
                </li>

                <!-- Search -->
                <li class="nb-layout-sidebar__search">
                    <Button class="text-white" outlined text>
                        <template #icon>
                            <i class="pi pi-search"></i>
                        </template>
                    </Button>
                </li>

                <!-- @todo: update icons to accommodate both phosphor and primeicons -->
                <li v-for="(menu, index) in model[0]?.items" :key="index">
                    <Button
                        @click="handleMenuClick(menu)"
                        :class="{ 'active-menu': isParentMenuActive(menu) }"
                        class="text-white" outlined text
                    >
                        <template #icon>
                            <i :class="menu.icon"></i>
                        </template>
                    </Button>
                    <AppMenuTooltip :label="menu.label" />
                </li>

                <!-- Switch to Cumulus-->
                <li class="nb-layout-sidebar__switcher">
                    <Button class="text-white" outlined text>
                        <template #icon>
                            <i class="pi pi-arrow-left"></i>
                        </template>
                    </Button>
                    <AppMenuTooltip label="Switch to Cumulus" />
                </li>
            </menu>

            <div class="nb-layout-sidebar__sub-menu">
                <div style="height: 100px">
                    <!-- Note: https://emersion.atlassian.net/browse/DEV-4144?focusedCommentId=234430 -->
                </div>

                <div v-if="selectedMenu">
                    <label class="font-bold">{{ selectedMenu.label }}</label>
                    <menu role="menu">
                        <li v-for="subMenu in selectedMenu?.items">
                            <router-link :to="subMenu.to" active-class="active-menu">
                                {{ subMenu.label }}
                            </router-link>
                        </li>
                    </menu>
                </div>
            </div>
        </nav>
    </aside>
</template>

<script>
import { useEUPStore } from '@/stores/eup-store';
import { computed, onMounted, ref, watchEffect } from 'vue';
import { getEndUserPortalHost } from '@/service/EndUserPortalService';
import HandoverToken from './service/HandOverToken';
import UserAuthenticationService from './service/UserAuthenticationService';
import AppMenuTooltip from '@/AppMenuTooltip.vue';
import { useRoute } from 'vue-router';

export default {
    name: 'AppMenu',
    emits: ['menu-click', 'menuitem-click', 'root-menuitem-click'],
    props: {
        model: Array,
        layoutMode: String,
        active: Boolean,
        mobileMenuActive: Boolean,
    },
    methods: {
        onMenuClick(event) {
            this.$emit('menu-click', event);
        },
        onMenuItemClick(event) {
            this.$emit('menuitem-click', event);
        },
        onRootMenuItemClick(event) {
            this.$emit('root-menuitem-click', event);
        },
    },
    components: {
        AppMenuTooltip,
    },
    setup(props) {
        const route = useRoute();
        const eupStore = useEUPStore();
        const isEUP = ref(false);
        const selectedMenu = ref(null);

        onMounted(() => {
            // by default set the eup settings in state
            useEUPStore().setEUPSettings();
            isEUP.value = !!getEndUserPortalHost();
        });

        watchEffect(() => {
            if (props.model && props.model.length > 0) {
                const activeMenu = props.model[0]?.items.find(menu => {
                    return isParentMenuActive(menu);
                });
                selectedMenu.value = activeMenu || null;
            }
        });

        const smallLogo = computed(() => {
            return eupStore.eupSettings.public_path
                ? eupStore.eupSettings.public_path.small_logo
                : 'assets/layout/images/emersion-logo-white.png';
        });

        const switchToCumulus = () => {
            let handoverToken = new HandoverToken();
            let userService = new UserAuthenticationService();

            handoverToken
                .requestHandOver(userService.getBearer())
                .then((res) => {
                    window.location.href = process.env.VUE_APP_CUMULUS_URL + '?handover_token=' + res.data.handover_token;
                })
                .catch(() => {
                    this.$router.push('/error');
                });
        };

        const handleMenuClick = (menu) => {
            selectedMenu.value = menu;
        };

        const isParentMenuActive = (menu) => {
            return menu.items?.some(subMenu => route.path === subMenu.to);
        };

        return {
            smallLogo,
            isEUP,
            switchToCumulus,
            selectedMenu,
            handleMenuClick,
            isParentMenuActive,
        };
    },
};
</script>
